/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "Sora-Bold";
  src: url("./assets/fonts/Sora/static/Sora-Bold.ttf");
}
@font-face {
  font-family: "Sora-Semibold";
  src: url("./assets/fonts/Sora/static/Sora-SemiBold.ttf");
}
@font-face {
  font-family: "Sora-Regular";
  src: url("./assets/fonts/Sora/static/Sora-Regular.ttf");
}
@font-face {
  font-family: "Sora-Light";
  src: url("./assets/fonts/Sora/static/Sora-Light.ttf");
}
.bg {
  background: url(/assets/images/background-image.png) no-repeat;
  background-size: cover;
}
.otp-input {
  width: 50px;
  height: 50px;
  border-radius: 0px !important;
  border-bottom: solid 2px #fcfcf0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  text-align: center;
  /* border: none; */
  font-size: 32px;
  outline: none;
  background: transparent;
  border-right: 0 !important;
  color: #fcfcf0;
  font-family: "Sora-Regular";
}
.custom-modalbox {
  mat-dialog-container {
    padding: 0;
  }
}
.mat-dialog-container {
  background: transparent;
  padding: 0 !important;
  text-align: center;
  box-shadow: none;
}
::ng-deep .cdk-overlay-container {
  background: rgb(0 0 0 / 60%) !important;
}

.nodata {
  margin: 5%;
  text-align: center;
  font-size: large;
  font-family: "Sora-Semibold";
}
::ng-deep .mat-form-field-underline {
  display: none !important;
}
.allreportsdownload {
  text-align: end;
  margin-right: 1%;
  button {
    background: #2b2b2b 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    outline: none;
    border: none;
    color: #fcfcf0;
    font-family: "Sora-Semibold";
    text-transform: capitalize;
    padding: 1%;
    margin-right: 1%;
    margin-bottom: 2%;
  }
}
.fixedcard {
  height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.fixedcard1{
  height: 67vh;
  overflow-y: auto;
}
.loghistory{
  float: right;
  background: white;
  color: black;
  padding: 2px;
  cursor: pointer;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px; 
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.322);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
}
// .row{
//   margin: auto;
// }
